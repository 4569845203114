import React, { useEffect, useState, Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import BoxAndWhiskerPlot from "../components/barandwhisker.js"
import YieldChart from "../components/barchart.js"
import Dropdown from 'react-bootstrap/Dropdown'
import IndexChart from "../components/indexchart.js"
import '../styles/mystyles.css'
import LogoBanner from "../components/logobanner.png"
import ReturnVarianceChart from "../components/returnvariancechart.js"
import CAPESWRChart from "../components/CAPESWRchart.js"
import CAPEEndValueChart from "../components/CAPEendvaluechart.js"

const Returns = () => { 

	const API_HOST = 'http://localhost:8000';

    // const API_HOST = 'https://ukfinindbackend-eaa5c6e3a567.herokuapp.com';

    let _csrfToken = null

	const api_key = 'seh3599f@fUERkdf22&^2{u'

	// ideally need to update below so it auto links from set data for gilt coupon...

	const [requestObject, setRequestObject] = useState({'period' : 5, 'bond_coupon' : 3, 'index_bond_coupon' : 0.5, 'data_option' : 2, 'data_start_year': 1899, 'data_end_year': 2023, 'currency_set': 'USD', 'geographic_set': 'DOMESTIC'})

	const [selectedOption, setSelectedOption] = useState(5);

	const [selectedCurrency, setSelectedCurrency] = useState('USD (US Equity, US Conventional Gvmt 10y Bonds, US CPI, 1900-2023)');

	// this is in order to control text size depending on screen size
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	const [chartData, setChartData] = useState({
			'equity_nominal': [],
			'bond_nominal': [],
			'index_bond_nominal': [],
			'cpi_nominal': [],
			'equity_nominal_avg': 0.0,
			'bond_nominal_avg': 0.0,
			'index_bond_nominal_avg': 0.0,
			'cpi_nominal_avg': 0.0,
			'equity_real': [],
			'bond_real': [],
			'index_bond_real': [],
			'equity_real_avg': 0.0,
			'bond_real_avg': 0.0,
			'index_bond_real_avg': 0.0,
	});

	const [chartData2, setChartData2] = useState({
		'equity_nominal': [],
		'bond_nominal': [],
		'index_bond_nominal': [],
		'cpi_nominal': [],
		'equity_nominal_avg': 0.0,
		'bond_nominal_avg': 0.0,
		'index_bond_nominal_avg': 0.0,
		'cpi_nominal_avg': 0.0,
		'equity_real': [],
		'bond_real': [],
		'index_bond_real': [],
		'equity_real_avg': 0.0,
		'bond_real_avg': 0.0,
		'index_bond_real_avg': 0.0,
	});

	const [chartData3, setChartData3] = useState({
		'index_bond_forward_select' : [],
        'bond_forward_select' : [],
        'forward_chart_labels' : [],
	});

	const [chartData4, setChartData4] = useState({
		'five' : [],
        'ten' : [],
        'twenty' : [],
        'thirty' : [],
	});

	const [chartData5, setChartData5] = useState({
		'equity' : [],
		'bond' : [],
		'years' : [],
	});

	const [chartData6, setChartData6] = useState({
		'one' : [],
		'ten' : [],
		'twenty' : [],
	})

	const [chartData7, setChartData7] = useState({
		'year': [],
		'CAPE': [],
		'SWR': [],
		'end_value': [],
	})

    const [updatedate, setUpdatedate] = useState();

	const handleSelectChange = (eventKey) => {
		setSelectedOption(eventKey);
		setRequestObject({...requestObject,['period']:parseInt(eventKey)});
	};

	const handleCurrencyChange = (eventKey) => {
		if (eventKey == 1) {setSelectedCurrency('GBP (Global Equity, UK Conventional Gvmt 10y Bonds, UK CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'GBP', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})} 
		else if (eventKey == 2) {setSelectedCurrency('USD (US Equity, US Conventional Gvmt 10y Bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'DOMESTIC', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})}
		else if (eventKey == 3) {setSelectedCurrency('USD (US Equity, US Conventional Gvmt 10y Bonds, US CPI, 1870-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'DOMESTIC', ['data_start_year']: 1870, ['data_option']:parseInt(eventKey)})}
		else {setSelectedCurrency('USD (Global Equity, US Conventional Gvmt 10y Bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})};
	};

	useEffect(() => {
		handleSubmit()
	}, []);

	useEffect(() => {
		handleSubmit()
	}, [requestObject]);

    const handleSubmit = async () => {
		try {
		const response = await fetch('/api/historics/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-API-KEY': api_key // Set the custom header here
				},
		  	body: JSON.stringify(requestObject),
	});
		const data = await response.json();

		setChartData6({
			'one': data.returnvariance.one,
			'ten': data.returnvariance.ten,
			'twenty': data.returnvariance.twenty,
		})

		setChartData({
			'equity_nominal': data.deciles_equity_nominal_1,
			'bond_nominal': data.deciles_bond_nominal_1,
			'index_bond_nominal': data.deciles_index_bond_nominal_1,
			'cpi_nominal': data.deciles_cpi_change_1,
			'equity_nominal_avg': data.avg_equity_nominal_1,
			'bond_nominal_avg': data.avg_bond_nominal_1,
			'index_bond_nominal_avg': data.avg_index_bond_nominal_1,
			'cpi_nominal_avg': data.avg_cpi_change_1,
			'equity_real': data.deciles_equity_real_1,
			'bond_real': data.deciles_bond_real_1,
			'index_bond_real': data.deciles_index_bond_real_1,
			'equity_real_avg': data.avg_equity_real_1,
			'bond_real_avg': data.avg_bond_real_1,
			'index_bond_real_avg': data.avg_index_bond_real_1,
		});

		setChartData2({
			'equity_nominal': data.deciles_equity_nominal_5,
			'bond_nominal': data.deciles_bond_nominal_5,
			'index_bond_nominal': data.deciles_index_bond_nominal_5,
			'cpi_nominal': data.deciles_cpi_change_5,
			'equity_nominal_avg': data.avg_equity_nominal_5,
			'bond_nominal_avg': data.avg_bond_nominal_5,
			'index_bond_nominal_avg': data.avg_index_bond_nominal_5,
			'cpi_nominal_avg': data.avg_cpi_change_5,
			'equity_real': data.deciles_equity_real_5,
			'bond_real': data.deciles_bond_real_5,
			'index_bond_real': data.deciles_index_bond_real_5,
			'equity_real_avg': data.avg_equity_real_5,
			'bond_real_avg': data.avg_bond_real_5,
			'index_bond_real_avg': data.avg_index_bond_real_5,
		});

		setChartData3({
			'index_bond_forward_select' : data.index_bond_forward_select,
			'bond_forward_select' : data.bond_forward_select,
			'index_bond_forward_select_us' : data.index_bond_forward_select_us,
			'bond_forward_select_us' : data.bond_forward_select_us,
			'forward_chart_labels' : data.forward_chart_labels,
		});

		setChartData4({
			'five' : data.five,
			'ten' : data.ten,
			'twenty' : data.twenty,
			'thirty' : data.thirty,
		});

		setChartData5({
			'equity' : data.equity_chart,
			'bond' : data.bond_chart,
			'years' : data.years
		})

		setChartData7({
			'year': data.valuation_chart_year,
			'CAPE': data.valuation_chart_CAPE,
			'SWR': data.valuation_chart_SWR,
			'end_value': data.valuation_chart_end_value,
		})

		setUpdatedate(data.update_date.toString().slice(0, -9));

		} catch (error) {console.log(error)};
	  };

	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
			<Helmet>
            	<title>Retire Smart Calc - Nominal And Real Historic Returns On Equities And Government Bonds</title>
            	<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/returns"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
				
				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			  </Helmet>


			<Row className="h-100 d-flex align-items-end" style={{ paddingTop: '50px' }}>
			<Col xs={0} sm={1} />
			<Col xs={12} sm={10} className="rounded p-3 mt-2 mb-2">
			  {/* <h1 className="display-5">Retire Smart Calc</h1> */}
			  {isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
				<p className="lead"></p>
				{/* <hr className="my-4"></hr> */}
				<h2 className="lead">Historic nominal and real returns on equities vs. bonds:</h2>
				<hr className="my-1"></hr>
				<p className={isSmallScreen ? 'small' : ''}>The first interactive chart below allows you to see the range of historic returns (up to end 2023) from equities and conventional bonds (10 year government bond) both in nominal and real terms (i.e. adjusted for inflation). The second chart shows the real term index growth of equites vs. conventional bonds for the selected data-set. The third chart shows the impact of different asset mix combinations of equities and conventional bonds on historic real returns vs. volatility (standard deviation) of real returns over different holding periods for the selected data-set (with annual rebalancing of the target asset mix during those holding periods). The forth and fifth charts show the historical relationship between relative equity market valuation and the safe withdrawal rate or end period portfolio balance for a 30 year drawdown equity portfolio. </p>

				<div className="d-flex align-items-center">
						<Dropdown onSelect={handleSelectChange} className='me-2'>
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							Choose a time period
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item eventKey="3">3 years</Dropdown.Item>
							<Dropdown.Item eventKey="5">5 years</Dropdown.Item>
							<Dropdown.Item eventKey="10">10 years</Dropdown.Item>
							<Dropdown.Item eventKey="15">15 years</Dropdown.Item>
							<Dropdown.Item eventKey="20">20 years</Dropdown.Item>
							<Dropdown.Item eventKey="25">25 years</Dropdown.Item>
						</Dropdown.Menu>
						</Dropdown>

						<Dropdown onSelect={handleCurrencyChange}>
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							Choose data set
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item eventKey="2">USD (US equities in USD, US conventional gvmt 10y bonds, US CPI 1900-2023)</Dropdown.Item>
							<Dropdown.Item eventKey="3">USD (US equities in USD, US conventional gvmt 10y bonds, US CPI 1870-2023)</Dropdown.Item>
							<Dropdown.Item eventKey="4">USD (Global equities in USD, US conventional gvmt 10y bonds, US CPI 1900-2023)</Dropdown.Item>
							<Dropdown.Item eventKey="1">GBP (Global equities in GBP, UK conventional gvmt 10y bonds, UK CPI 1900-2023)</Dropdown.Item>
						</Dropdown.Menu>
						</Dropdown>
			</div>


			<br></br>
			<h6>{selectedOption} year historic asset class returns ({selectedCurrency}, by selected percentiles):</h6>
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2 mb-2">
			<BoxAndWhiskerPlot data={chartData2} heightratio={1}/>
			{/* <p className="fw-lighter small">Note: the mid point in each bar shows the average return rather than the median.</p> */}
			</div>
			<div className="col-lg-2"></div>
			</div>

			<br></br>
			<h6>Accumulated historic real returns by asset class ({selectedCurrency}):</h6>	
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2" style={{ marginBottom: '40px' }}>		
			<IndexChart data={chartData5}/>
			</div>
			<div className="col-lg-2"></div>
			</div>

			<h6>Average real returns and volatility of returns by asset class mix and holding period (with annual rebalancing) ({selectedCurrency}):</h6>	
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2" style={{ marginBottom: '40px' }}>		
			<ReturnVarianceChart data={chartData6}/>
			</div>
			<div className="col-lg-2"></div>
			</div>	

			<h6>Relationship between US equity market CAPE (at beginning period) and max back-tested safe withdrawal rate (through period for 100% US equity S&P500 portfolio) for historic 30 year periods (beginning 1870-1993):</h6>	
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2" style={{ marginBottom: '40px' }}>		
			<CAPESWRChart data={chartData7}/>
			</div>
			<div className="col-lg-2"></div>
			</div>	

			<h6>Relationship between US equity market CAPE (at beginning period) and end period portfolio value (using a 3.8% withdrawal rate on 100% US equity S&P500 portfolio) for historic 30 year periods (beginning 1870-1993):</h6>	
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2" style={{ marginBottom: '40px' }}>		
			<CAPEEndValueChart data={chartData7}/>
			</div>
			<div className="col-lg-2"></div>
			</div>	

			{/* <h2 className="lead">Forward looking bond returns:</h2>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>The chart below shows the current market yields that are used in the simulation calculator when 'current market yields for bonds, historic data for equities' is selected as a back-testing dataset. The expected return will be the market yield if the bond is held to maturity. Note inflation linked bonds can trade (and have traded) at market yields that will return less than inflation.</p>
			<h6>Current UK & US government bond market yields (%):</h6>
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2">
			<YieldChart data={chartData4}/>
			<p className="fw-lighter small">Note: inflation linked yields are 'premium' or 'discount' in addition to returning the rate of inflation. Market data as of: {updatedate}</p>
			</div>
			<div className="col-lg-2"></div>
			</div>
			<div className="my-4"></div> */}


			<h2 className="lead">Take aways:</h2>			
			<hr className="my-1"></hr>
			<ul>
			<li>For 5-10+ year investment horizons, conventional government bonds are not always 'safer' than equities (because of inflation risk). In the two high inflation periods (post WW1 and early 70’s), both equity returns and conventional government bond returns did badly in real terms.</li>
			<li>Real return volatility can be reduced by holding both equities and conventional government bonds.  For a 20+ year investment horizon, a 60:40 or 50:50 portfolio has historically minimised volatility.  For a shorter horizon (e.g. 10 years or less), a 20-40% allocation to equity and 40-60% to conventional government bonds has minimised real-return volatility.</li>
			{/* <li>For a 30 year retirement drawdown portfolio, the simulation calculator using historical data (and allocations between equities and conventional government bonds) finds the highest back-tested safe withdrawal rates (SWR) with an equity allocation of between 70-100% and a conventional government bond allocation of between 0-30%. To take this approach, investors need to be comfortable with high return volatility (both nominal and real) than with a 60:40 or 50:50 portfolio. Those that are not should accept a lower SWR.</li>
			<li>Inflation linked government bonds offer a guaranteed return post inflation (if the bonds are held to maturity).  Investors may consider whether these are a better option than conventional bonds for their portfolio.  (Due to the limited time that inflation linked government bonds have been issued, we can not test their historical performance in the same way that we can for equity and conventional bonds).  Investors should be aware that the mark to market price volatility on long dated inflation linked bonds can be greater than for equities.  Investors may also consider other guaranteed income options (e.g. index linked annuities, delaying social security / state pension and drawing down more of their portfolio in the interim, etc).</li>
			<li>Introducing inflation linked government bonds and/or other guaranteed income assets into the simulation calculator portfolio allocation shows that the back-tested SWR may be increased over a equity and conventional government bond only portfolio (depending on where real interest rates are).</li> */}
			<li>Shiller and other academic research has shown a strong relationship between equity market valuation and future equity returns. This link can be extended to an inverse relationship between equity market valuation and the maximum SWR and an inverse relationship between equity market valuation and the remaining portfolio value at the end of a retirement drawdown period. <a href="https://www.multpl.com/shiller-pe" className="text-info" target="_blank" rel="noopener noreferrer">Current and historic Shiller CAPE ratio data.</a>.</li>
			</ul>

			</Col> 
			<Col xs={0} sm={1} />
			</Row>
		</Container>
	); 
	}; 

	export default Returns;
	 
	  
	  
	  
	  