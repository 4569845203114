import React, { useEffect, useState, Component } from 'react'
import { Scatter } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, Tooltip, Legend);


const ReturnVarianceChart = (props) => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * 0.3; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  var my_font_size = isSmallScreen ? 8 : 12; 
  var my_font_size_small = isSmallScreen ? 6: 10;


  const scatterData = {
    datasets: [
      {
        label: 'Scatterplot of CAPE vs end period portfolio value remaining',
        data: props.data.CAPE.map((x, index) => ({
          x: x,
          y: props.data.end_value[index] / 1000000,
        })),
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
    ],
  };

  // Chart options
  const scatterOptions = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
        position: 'top',
        labels: {
          color: 'white',
          font: {size : my_font_size,},
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `CAPE: ${context.raw.x}, SWR: ${context.raw.y}`,
        },
      },
    },

    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
          font: {size : my_font_size,},
        },
        title: {
          display: true,
          text: 'CAPE',
          color: 'white',
          font: {
            size: my_font_size, // Set the font size for the axis title
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Set y-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set y-axis ticks color to white
          font: {size : my_font_size,},
        },
        title: {
          display: true,
          text: 'End period portfolio value ($ MM)',
          color: 'white',
          font: {
            size: my_font_size, // Set the font size for the axis title
          },
        },
      },
    },
  };


  return (
    <div>
    {isSmallScreen ? (
      <div style={{ width: '100%', height: '300px' }}>
        {/* <Scatter data={scatterData} options={scatterOptions} plugins={[ChartDataLabels]} /> */}
        <Scatter data={scatterData} options={scatterOptions}/>
      </div>
    ) : (
      <div style={{ width: '100%', height: '400px' }}>
        {/* <Scatter data={scatterData} options={scatterOptions} plugins={[ChartDataLabels]} /> */}
        <Scatter data={scatterData} options={scatterOptions}/>
      </div>
    )}
    </div>
  );

};

export default ReturnVarianceChart;

