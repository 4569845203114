import { create } from 'zustand'

// Define the initial state
const initialPresetObject = {
  plan_title: "Plan",
  plan_date: new Date().toISOString().split('T')[0],
  historic_asset_return_data: {},
  forward_asset_return_data: {},
  mortality_data: {},
  data_start_year: 1870,
  data_end_year: 2023,
  currency_set: 'USD',
  geographic_set: 'DOMESTIC',
  equity_tax: 0,
  bond_tax: 0,
  draw_tax: 0,
  bond_coupon: 3,
  index_bond_coupon: 0.5,
  asset_mix_equity: 80,
  asset_mix_bond: 20,
  asset_mix_index_bond: 0,
  asset_mix_source: "1",
  asset_mix_equity_2: 40,
  asset_mix_bond_2: 60,
  asset_mix_index_bond_2: 0,  
  age_start_transition: 57,
  age_end_transition: 65,
  data_option: "forwardUSD",
  data_direction: "forward",
  dynamic_option: "constant",
  withdrawal_percent: 4.0,
  target_withdrawal_percent: 5,
  net_other_income: "0",
  yale_weighting: 70,
  vanguard_decrease_floor: 1.5,
  vanguard_increase_ceiling: 5,
  annuity_tax_rate: 0,
  annuity_tax_rate2: 0,
  annuity_tax_rate3: 0,
  fees: 0,
  spread: 0,
  equity_alpha: 0,
  fixed_income_alpha: 0,
  start_simulation_age: 65,
  end_simulation_age: 100,
  year_retire: 65,
  circular_simulation: "1",
  extraordinary_inflows: [],
  state_pension: [],
  occupational_pension: [],
  annuity_pension: [],
  contribution_inflows: [],
  income_requirements: [],
  ladder_income_requirements: [],
  flex_income_requirements: [],
  asset_portfolio: [],
  asset_deduction: [{equity_ga: 0, bonds_ga: 0, index_ga: 0, equity_te: 0, bonds_te: 0, index_te: 0, equity_td: 0, bonds_td: 0, index_td: 0}],
  apply_tax_to_inflation: "0",
  bond_ladder: [],
  bond_matching_option: "0",
  ladder_tax: 0,
  ladder_cash_rate: 0,
  roll_up_unspent_coupons: "0",
  annuity_price_scale: 85,
  asset_mix_changes: [],
  bond_portfolio: [],
};

// Create Zustand store
export const usePresetStore = create((set) => ({
  presetObject: initialPresetObject,
  planId: null, // Add planId to the global store
  autoRunSimulation: false,

  // Function to set presetObject
  setPresetObject: (newData) => set((state) => ({
    presetObject: {
      ...state.presetObject,
      ...newData,
    },
  })),

  // Function to reset presetObject
  resetPresetObject: () => set((state) => ({
    presetObject: {
      historic_asset_return_data: state.presetObject.historic_asset_return_data,
      forward_asset_return_data: state.presetObject.forward_asset_return_data,
      mortality_data: state.presetObject.mortality_data,
      ...initialPresetObject,
      historic_asset_return_data: state.presetObject.historic_asset_return_data,
      forward_asset_return_data: state.presetObject.forward_asset_return_data,
      mortality_data: state.presetObject.mortality_data,
    },
  })),

  // Function to set planId
  setPlanId: (planId) => set(() => ({
    planId: planId,
  })),

  // Function to reset planId
  resetPlanId: () => set(() => ({
    planId: null,
  })),

  triggerAutoRunSimulation: () => set(() => ({
    autoRunSimulation: true,
  })),

  resetAutoRunSimulation: () => set(() => ({
    autoRunSimulation: false
  })),

}));

