import React, { useEffect, useState, Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Table } from 'react-bootstrap';
import LogoBanner from "../components/logobanner.png"

const TakeAways = () => { 

// this is in order to control text size depending on screen size
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};


	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
			<Helmet>
				<title>Retire Smart Calc - Observations On Safe Withdrawal Rates From Back-testing And Strategies To Improve</title>
				<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/observations"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />			
				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			  </Helmet>

			<Row className="h-100 d-flex align-items-end" style={{ paddingTop: '50px' }}>
			<Col xs={0} sm={1} />
			<Col xs={12} sm={10} className="rounded p-3 mt-2 mb-2">
			{isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
			<h2 className="lead">Take aways:</h2>			
			<hr className="my-1"></hr>
			<ul>
			<li>For 5-10+ year investment horizons, conventional bonds are not always safer than equities (because of inflation risk). In the two high inflation periods (post WW1 and early 70’s), both equity returns and conventional bond returns did badly in real terms.</li>
			<li>Real return volatility can be reduced by holding both equities and conventional bonds.  For a 20+ year investment horizon, a ~60:40 portfolio has historically minimised volatility.  For a shorter horizon (e.g. 10 years or less), a 20-40% allocation to equity and 40-60% to conventional bonds has minimised real-return volatility.</li>
			<li>For a 30 year retirement drawdown portfolio, allocation optimisation modelling on historical data (between equities and government conventional bonds) finds the highest max back-tested safe withdrawal rate (SWR) with an equity allocation of between 70-100% and a conventional bond allocation of between 0-30%.</li>
			<li>Government inflation linked bonds offer a guaranteed return post inflation (if the bonds are held to maturity).  Investors may consider whether these are a better option than conventional bonds for their portfolio.  (Due to the limited time that government index linked bonds have been issued, we can not test their historical performance in the same way that we can for equity and conventional bonds).  Investors should be aware that the mark to market price volatility on long dated inflation linked bonds can be greater than equities.  Investors may also consider other guaranteed income options (e.g. index linked annuities, delaying social security / state pension and drawing down more of their portfolio in the interim, etc).</li>
			<li>Introducing government index linked bonds and/or other guaranteed income assets into allocation optimisation modelling, shows that the back-tested SWR may be increased over a equity and conventional government bond only portfolio (depending on where real interest rates are).</li>
			<li>Shiller and other academic research has shown a strong relationship between equity market valuation and future equity returns. This link can be extended to an inverse relationship between equity market valuation and the maximum SWR and an inverse relationship between equity market valuation and the remaining portfolio value at the end of a retirement drawdown period.  Current equity market valuations are very high by historical standards - <a href="https://www.multpl.com/shiller-pe" target="_blank" rel="noopener noreferrer">Shiller CAPE Ratio</a>.</li>
			</ul>
			</Col> 
			<Col xs={0} sm={1} />
		</Row>
		</Container>
	); 
	}; 

	export default TakeAways;
	 
	  
	  
	  
	  