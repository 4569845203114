import React, { useEffect, useState, Component } from 'react'
import { Container, Row, Col, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import BoxAndWhiskerPlot from "../components/barandwhisker.js"
import YieldChart from "../components/barchart.js"
import Dropdown from 'react-bootstrap/Dropdown'
import IndexChart from "../components/indexchart.js"
import '../styles/mystyles.css'
import LogoBanner from "../components/logobanner.png"
import ReturnVarianceChart from "../components/returnvariancechart.js"
import '../App.css' // Import the CSS file
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { FaInfoCircle } from 'react-icons/fa'; // Font Awesome info icon

const Returns = () => { 

	const api_key = 'seh3599f@fUERkdf22&^2{u';
	// this is in order to control text size depending on screen size
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const [currencyChoice, setCurrencyChoice] = useState('USD')
	const [currencyIcon, setCurrencyIcon] = useState('$');

    useEffect(() => {
		const fetchUserLocation = async () => {
		  let apiroute = ""
		  try {
		  apiroute = "getlocation";
		  const response = await fetch(`/api/${apiroute}/`, {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json',
			  'X-API-KEY': api_key, // Set the custom header here
			  },
		  });
		  const data = await response.json();
		  if (data.country === 'GB') {
			setCurrencyChoice('GBP')
		  } else {
			setCurrencyChoice('USD')
		}
		  } catch (error) {console.error("Error fetching data:", error)} 
		}
		fetchUserLocation();
	  }, []);

	  useEffect(() => {
		if (currencyChoice === 'GBP') {
			setCurrencyIcon('\u00A3')
		} else {
			setCurrencyIcon('$')
		}
	  }, [currencyChoice])

	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	const [dataGBP, setDataGBP] = useState({
		'_annuitySWR': 0,
		'_100SWR': 0,
		'_7030SWR': 0,
		'_4060SWR': 0,
		'_2080annuitySWR': 0,
		'_40annuitySWR': 0,
		'_annuityLegacy': 0,
		'_100Legacy' : 0,
		'_7030Legacy': 0,
		'_4060Legacy': 0,
		'_2080annuityLegacy': 0,
		'_40annuityLegacy': 0,
		'update_date': ''
	  })
	
	  const [dataUSD, setDataUSD] = useState({
		'_annuitySWR': 0,
		'_100SWR': 0,
		'_7030SWR': 0,
		'_4060SWR': 0,
		'_2080annuitySWR': 0,
		'_40annuitySWR': 0,
		'_annuityLegacy': 0,
		'_100Legacy' : 0,
		'_7030Legacy': 0,
		'_4060Legacy': 0,
		'_2080annuityLegacy': 0,
		'_40annuityLegacy': 0,
		'update_date': ''
	  })

	const getData = async () => {
		try {
			const apiroute = "getsummarytable";
			const response = await fetch(`/api/${apiroute}/`, {  // Use the variable `apiroute`
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-API-KEY': api_key, // Set the custom header here
				},
			});
			const data = await response.json();
			// console.log(data);
			setDataGBP({
				'_annuitySWR': data._0AnnuityGBPSWR,
				'_100SWR' : data._100GBPSWR,
				'_7030SWR': data._7030GBPSWR,
				'_4060SWR': data._4060GBPSWR,
				'_2080annuitySWR': data._2080AnnuityGBPSWR,
				'_40annuitySWR': data._40AnnuityGBPSWR,
				'_annuityLegacy': (data._0AnnuityGBPLegacy / 1000000),
				'_100Legacy' : (data._100GBPLegacy / 1000000),
				'_7030Legacy': (data._7030GBPLegacy / 1000000),
				'_4060Legacy': (data._4060GBPLegacy / 1000000),
				'_2080annuityLegacy': (data._2080AnnuityGBPLegacy / 1000000),
				'_40annuityLegacy': (data._40AnnuityGBPLegacy / 1000000),
				'update_date': (data.update_date.toString().slice(0, -9))
			});
			setDataUSD({
				'_annuitySWR': data._0AnnuityUSDSWR,
				'_100SWR' : data._100USDSWR,
				'_7030SWR': data._7030USDSWR,
				'_4060SWR': data._4060USDSWR,
				'_2080annuitySWR': data._2080AnnuityUSDSWR,
				'_40annuitySWR': data._40AnnuityUSDSWR,
				'_annuityLegacy': (data._0AnnuityUSDLegacy / 1000000),
				'_100Legacy' : (data._100USDLegacy / 1000000),
				'_7030Legacy': (data._7030USDLegacy / 1000000),
				'_4060Legacy': (data._4060USDLegacy / 1000000),
				'_2080annuityLegacy': (data._2080AnnuityUSDLegacy / 1000000),
				'_40annuityLegacy': (data._40AnnuityUSDLegacy / 1000000),
				'update_date': (data.update_date.toString().slice(0, -9))
			});
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		getData();
	}, [])

	const StatusCircles = () => {
		return (
		  <div>
			<div className="circle green"></div>
			<div className="circle amber" style={{ marginLeft: '10px' }}></div>
			<div className="circle red" style={{ marginLeft: '10px' }}></div>
		  </div>
		);
	  };

	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
			<Helmet>
            	<title>Retire Smart Calc - Optimising Plan Risk & Bond Ladder</title>
            	<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/returns"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
				
				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			  </Helmet>
			<Row className="h-100 d-flex align-items-end" style={{ paddingTop: '50px' }}>
			<Col xs={0} sm={1} />
			<Col xs={12} sm={10} className="rounded p-3 mt-2 mb-2">
			  {isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<div className="my-4"></div>
			<h2 className="lead">Decumulation plan risk and asset-mix strategies:</h2>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>The table below summarises how different asset types increase or reduce plan risk, as well as their impact on plan flexibility and the potential for money to be left at the end of the plan to provide an inheritence or legacy. (red = disadvantage;  green = advantage)</p>
			<div style={{ overflowX: 'auto' }}>
			<Table striped bordered hover variant="dark">
			<thead>
				<tr>
				<th style={{ width: '19%' }} className={isSmallScreen ? 'small' : ''}></th>
				<th style={{ width: '13.5%' }} className={isSmallScreen ? 'small' : ''}>Volatility risk</th>
				<th style={{ width: '13.5%' }} className={isSmallScreen ? 'small' : ''}>Inflation risk</th>
				<th style={{ width: '13.5%' }} className={isSmallScreen ? 'small' : ''}>Longevity risk</th>
				<th style={{ width: '13.5%' }} className={isSmallScreen ? 'small' : ''}>Flexibility</th>
				<th style={{ width: '13.5%' }} className={isSmallScreen ? 'small' : ''}>Legacy potential</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className={isSmallScreen ? 'small' : ''}>Equity</td>
					<td ><div className="circle red"/></td>
					<td ><div className="circle amber"/></td>
					<td ><div className="circle amber"/></td>
					<td ><div className="circle green"/></td>
					<td ><div className="circle green"/></td>
				</tr>
				<tr>
					<td className={isSmallScreen ? 'small' : ''}>Conventional bonds</td>
					<td><div className="green-amber-circle "/></td>
					<td><div className="circle red"/></td>
					<td><div className="circle red"/></td>
					<td><div className="circle green"/></td>
					<td><div className="circle amber"/></td>
				</tr>
				<tr>
					<td className={isSmallScreen ? 'small' : ''}>Inflation linked bonds</td>
					<td><div className="green-amber-circle "/></td>
					<td><div className="circle green"/></td>
					<td><div className="circle red"/></td>
					<td><div className="green-amber-circle"/></td>
					<td><div className="circle amber"/></td>
				</tr>
				<tr>
					<td className={isSmallScreen ? 'small' : ''}>Annuity (fixed / escalating)</td>
					<td><div className="circle green"/></td>
					<td><div className="circle red"/></td>
					<td><div className="circle green"/></td>
					<td><div className="circle red"/></td>
					<td><div className="circle red"/></td>
				</tr>
				<tr>
					<td className={isSmallScreen ? 'small' : ''}>Annuity (inflation linked)</td>
					<td><div className="circle green"/></td>
					<td><div className="circle green"/></td>
					<td><div className="circle green"/></td>
					<td><div className="circle red"/></td>
					<td><div className="circle red"/></td>
				</tr>
			</tbody>
			</Table>
			</div>
			<p className={isSmallScreen ? 'small' : ''}>Understanding these factors can help investors think about the suitability and trade-offs of common asset-mix strategies for decumulation portfolios:</p>
			<ul className={isSmallScreen ? 'small' : ''}>
			<li>Growth portfolio: maintain a high allocation to equities and other high risk-return assets from the accumulation portfolio. Potential for long-term growth provides some offset to inflation and longevity risk. Requires high investor risk appetite and tolerance for asset price volatility.</li>
			<li>Balanced portfolio: moderate allocation to equities combined with a mix of conventional and inflation linked bonds and possibly other moderate risk-return assets. Seeks to balance out specific plan risks.</li>
			<li>Index linked (bond ladder) portfolio: full lock-in of real returns but the lack of growth potential increases both longevity risk if the portfolio is not large enought to last to a maximum old age (e.g. 100+ years old) Portfolio should be duration / maturity matched to prevent unwanted asset price volatilty and ensure returns are locked-in.</li>
			<li>Annuitisation: reduces plan risk (more so if inflation linked) but at the expense of flexibility or legacy potential (annuities can not typically be sold or easily exited once bought). As age increases, an annuity will likely offer a higher income (or safe withdrawal rate equivalent) compared to other asset-mix strategies that are planned to last to a maximum old age.</li>
			<li>Mixed equity and guaranteed income portfolio: seeks to control asset volatility, inflation and longevity risks whilst still providing some growth potential for higher retirement spending or legacy portfolio.</li>
			</ul>

			{/* <p className={isSmallScreen ? 'small' : ''}>The table below show a set of example results from the simulation calculator that highlights the effect of reducing / diversifying plan risk to allow for a higher withdrawal rate in the retirement plan.</p>
			
			<div>
			{isSmallScreen ? <></> : <>
			<div style={{ position: 'relative' }}>
			<div
				style={{
				position: 'absolute',
				top: '-2rem',
				left: '37.5%',
				width: '25%',
				height: '2rem',
				backgroundColor: '#f0f0f0',
				textAlign: 'center',
				lineHeight: '2rem',
				fontWeight: 'bold',
				borderRadius: '5px',
				color: 'black', // Set text color to black
				}}
			>
				Reducing inflation risk...
			</div>

			<div
				style={{
				position: 'absolute',
				top: '-2rem',
				left: '62.5%',
				width: '37.5%',
				height: '2rem',
				backgroundColor: '#f0f0f0',
				textAlign: 'center',
				lineHeight: '2rem',
				fontWeight: 'bold',
				borderRadius: '5px',
				color: 'black', // Set text color to black
				}}
			>
				Reducing longevity risk...
			</div>
			</div>
			</>}	

			<div style={{ overflowX: 'auto', marginTop: '20px' }}>

			<Table striped bordered hover variant="dark" className={isSmallScreen ? 'small' : ''}>
				<thead>
					<tr>
						<th style={{ width: '25%' }}>30 year retirement plan for 65 year old with {currencyIcon}1 MM retirement savings</th>
						<th style={{ width: '12.5%' }}>100% equity</th>
						<th style={{ width: '12.5%' }}>70% equity: 30% index linked bonds<sup>1</sup></th>
						<th style={{ width: '12.5%' }}>40% equity: 60% index linked bonds<sup>1</sup></th>
						<th style={{ width: '12.5%' }}>20% equity: 80% ILB + deferred annuity<sup>2</sup></th>
						<th style={{ width: '12.5%' }}>40% equity: 60% (inflation linked) annuity<sup>3</sup></th>
						<th style={{ width: '12.5%' }}>100% (inflation linked) annuity<sup>4</sup></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
						<div className="d-flex align-items-center">
						<span>Safe withdrawal rate:</span>
						<OverlayTrigger
							placement="right"
							overlay={
							<Tooltip id="tooltip-right">SWR gives the amount (initial portfolio value * safe withdrawal rate %) that can be withdrawn each year whilst minimising the risk of exhausting the portfolio before then end of the plan. The withdrawal amount is indexed annually to consumer inflation. The SWR is calculated using back-testing over a 100+ years of historic market data.</Tooltip>}>
							<span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
							<FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
							</span>
						</OverlayTrigger>
						</div>
						</td>
						<td>{currencyChoice === 'GBP' ? dataGBP._100SWR.toFixed(1) : dataUSD._100SWR.toFixed(1)} %</td>
						<td>{currencyChoice === 'GBP' ? dataGBP._7030SWR.toFixed(1) : dataUSD._7030SWR.toFixed(1)} %</td>
						<td>{currencyChoice === 'GBP' ? dataGBP._4060SWR.toFixed(1) : dataUSD._4060SWR.toFixed(1)} %</td>
						<td>{currencyChoice === 'GBP' ? dataGBP._2080annuitySWR.toFixed(1) : dataUSD._2080annuitySWR.toFixed(1)} %</td>
						<td>{currencyChoice === 'GBP' ? dataGBP._40annuitySWR.toFixed(1) : dataUSD._40annuitySWR.toFixed(1)} %</td>
						<td>{currencyChoice === 'GBP' ? dataGBP._annuitySWR.toFixed(1) : dataUSD._annuitySWR.toFixed(1)} %</td>
					</tr>
					<tr>
						<td>
						<div className="d-flex align-items-center">
						<span>Median legacy portfolio:</span>
						<OverlayTrigger
							placement="right"
							overlay={
							<Tooltip id="tooltip-right">Median legacy portfolio is the median value of the portfolio at the end of the plan (after all withdrawals) across all back-test simulations.</Tooltip>}>
							<span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
							<FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
							</span>
						</OverlayTrigger>
						</div>
						</td>
						<td>{currencyIcon}{currencyChoice === 'GBP' ? dataGBP._100Legacy.toFixed(1) : dataUSD._100Legacy.toFixed(1)} MM</td>
						<td>{currencyIcon}{currencyChoice === 'GBP' ? dataGBP._7030Legacy.toFixed(1) : dataUSD._7030Legacy.toFixed(1)} MM</td>
						<td>{currencyIcon}{currencyChoice === 'GBP' ? dataGBP._4060Legacy.toFixed(1) : dataUSD._4060Legacy.toFixed(1)} MM</td>
						<td>{currencyIcon}{currencyChoice === 'GBP' ? dataGBP._2080annuityLegacy.toFixed(1) : dataUSD._2080annuityLegacy.toFixed(1)} MM</td>
						<td>{currencyIcon}{currencyChoice === 'GBP' ? dataGBP._40annuityLegacy.toFixed(1) : dataUSD._40annuityLegacy.toFixed(1)} MM</td>
						<td>{currencyIcon}{currencyChoice === 'GBP' ? dataGBP._annuityLegacy.toFixed(1) : dataUSD._annuityLegacy.toFixed(1)} MM</td>
					</tr>
				</tbody>
			</Table>
			</div>
			</div>

			<ToggleButtonGroup
				type="radio"
				name="options"
				value={currencyChoice} // This binds the selected value to `currencyChoice`
				onChange={(val) => setCurrencyChoice(val)} // Update `currencyChoice` based on selected button
				style={{ paddingBottom: '10px' }}
			>
				<ToggleButton
					className={isSmallScreen ? 'mini-toggle-button' : ''}
					id="btnUSD"
					value="USD" // Set value for the USD button
					variant="secondary"
					size="sm"
				>
					USD portfolios
				</ToggleButton> 
				<ToggleButton
					className={isSmallScreen ? 'mini-toggle-button' : ''}
					id="btnGBP"
					value="GBP" // Set value for the GBP button
					variant="secondary"
					size="sm"
				>
					GBP portfolios
				</ToggleButton> 
			</ToggleButtonGroup>

			<div className="fw-lighter small">{currencyChoice === 'GBP' ? 'GBP portfolio = historic global equities (denominated in GBP), forward looking UK bond yields, indicative UK annuity prices, UK data' : 'USD portfolio = historic US equities, forward looking US bond yields, indicative US annuity prices, US data'}</div>
			<div className="fw-lighter small">1) Portfolio rebalanced annually to maintain mix.</div>
			<div className="fw-lighter small">2) Portfolio of index linked bonds purchased at the beginning of the plan to match income requirements from 65 to 85. Deferred annuity (flat) also purchased at the beginning to provide a target 100% of the income requirement from age 85 (with the target set using expected inflation - if inflation is higher than expected, the annuity will cover less than 100% of the income requirement). The remaining portfolio is invested into equities.</div>
			<div className="fw-lighter small">3) 60% of portfolio is used to purchase an immediate, inflation-linked, lifetime annuity at the beginning. The remaining portfolio invested into equities.</div>
			<div className="fw-lighter small">4) 100% of portfolio is used to purchase an immediate, inflation-linked, lifetime annuity at the beginning.</div>

			<div className="fw-lighter small">Market data as of: {dataGBP.update_date}</div> */}

			<div className="my-4"></div>
			<h2 className="lead">Using the simulation calculator to help optimise plan risk:</h2>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>Once a plan has been input into the simulation calculator, the user can test how the asset mix changes the dispersion of potential outcomes and success rate of the plan. Using the forward market implied bond return option (under <em>back-testing data options</em>), the user can test allocations to equities, conventional bonds and inflation linked bonds.  The user can then use the <em>safe funding level by asset mix</em> analyser (which is inside the <em>safe funding analysis</em> option) to see the how this changes for different asset mix combinations. Further, the user can test allocating all or part of the portfolio to a lifetime annuity (either at the offset of the plan or at a later stage) by adding an immediate or deferred annuity purchase. Currently Retire Smart Calc does not yet have a feed for market annuity prices but there is an indicative annuity price table.  This gives users an indicative idea of prevailing annuity prices which it calculates using the market forward interest rate curve and life expectancy.  To get accurate prices, users will need to research themselves for these prices and input them into the simulation calculator. These prices can be found in investor publications or online.</p>


			<h2 className="lead">Implementing risk optimisation - index linked bond fund vs. segregated bond ladder portfolio:</h2>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>Investors looking to lock-in returns with inflation linked bonds can do this using bond mutual funds or a segregated portfolio of inflation linked bonds where the bond maturities match income requirements (a bond ladder portfolio).  The advantage of using a bond mutual fund is simplicity - the fund is simply sold down when required. The disadvantage is that the duration or average maturity of the fund may not match the investors' plan and create unwanted asset price volatility risk. Building a segregated bond ladder portfolio has the opposite advantages and disadvantages - close maturity matching can be achieved but it can be complex to build. Further, if expenditure plans change then the portfolio also needs to be adjusted. There may also be tax considerations - e.g. for UK investors, inflation linked bonds held in an ordinary fund (with no tax shelter) are subject to capital gains tax whereas bonds held directly are currently not.</p>
			<p className={isSmallScreen ? 'small' : ''}>Retire Smart Calc provides a bond ladder tool. Users can enter different expenditure items to create an expenditure schedule - note, this needs to be done seperately from the expenditure items in the <em>simulation calculator</em> plan and expenditure items need to be entered net (e.g. the amounts need to be withdrawn from the portfolio after considering other income such as state pension). The tool will then provide a ladder of government inflation linked bonds that match the expenditure schedule.</p>
			
			<h2 className="lead">Further reading:</h2>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>Detailed analysis on the impact of flexible spending strategies and the use of guaranteed income strategies in decumulation portfolios is available from <a className="text-info" href="https://www.morningstar.com/retirement/whats-safe-retirement-spending-rate-2025">Morningstar's State of Retirement Income report</a>.</p>
			</Col> 
			<Col xs={0} sm={1} />
			</Row>
		</Container>
	); 
	}; 

	export default Returns;
	 
	  
	  
	  
	  